import React from 'react';
import { Box, IconButton, InputAdornment, makeStyles, TextField, Tooltip, Typography, withStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const useStyle = makeStyles(theme => ({
    toolTipStyle: {
        maxWidth: '200px'
    },
    iconStyle: {
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
}))

const CustomTextField = withStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.contrastText,
        borderRadius: '4px'
    },
}))(props => {
    const classes = useStyle();
    return (
        <TextField
            {...props}
            fullWidth={props.fullWidth === false ? false : true}
            color='primary'
            variant='outlined'
            size='small'
            error={props.helperText && !props.noterror ? true : false}
            InputProps={{
                endAdornment: (
                    props.tooltip ?
                        <InputAdornment>
                            <Tooltip
                                title={<Typography variant='caption'>{props.tooltip}</Typography>}
                                placement='bottom'
                                classes={{ tooltip: classes.toolTipStyle }}
                                disableFocusListener
                                enterTouchDelay={1}
                                arrow
                            >
                                <IconButton className={classes.iconStyle} tabIndex={-1}><InfoIcon /></IconButton>
                            </Tooltip>
                        </InputAdornment>
                    : null
                ),
                startAdornment: (
                    props.startadornment && <InputAdornment>
                        <Box>{props.startadornment}</Box>
                    </InputAdornment>
                ),
                readOnly: props.readonly
            }}
        />
    )
})

export const TextFieldTemplate = (props) => (
    <CustomTextField {...props} />
)